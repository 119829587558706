import { Alert, ButtonGroup, Card, CardBody, CardFooter, FormGroup, Label, Modal, Table } from 'reactstrap';
import { Button, Input, PageTitle } from '../../components';
import { LetterTemplateDto, MeetingDateDto, ProfessionDto } from '../../common/Types';
import React, { useEffect, useRef, useState } from 'react';
import MultiSelect from 'react-multi-select-component';

import { APIUriAms } from '../../services/HttpHelper';
import Dropzone from 'react-dropzone';
import { ProgramStatusActiveOnly } from '../../common/Data';
import { professionService } from '../../services/ProfessionAdministration';
import styled from 'styled-components';
import { systemAdministration } from '../../services/SystemAdministration';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';

const DropZoneContainer = styled.section`
    border: dashed 5px #cccccc;
    > div {
        height: 80px;
    }
`;
const StyledMultiSelect = styled(MultiSelect)`
    width: 350px;

    & .dropdown-container {
        width: 100%;
        max-width: 100%;
    }
    & .dropdown-content {
        z-index: 3;
    }
    & .select-item {
        display: block;
    }
`;

interface ProfessionOption {
    label: string;
    value: number;
}
const LetterTemplatesUpdate = () => {
    const [templates, setTemplates] = useState<LetterTemplateDto[]>();
    const [professions, setProfessions] = useState<ProfessionDto[]>();
    const [professionOptions, setProfessionOptions] = useState<ProfessionOption[]>();
    const [selectedProfessions, setSelectedProfessions] = useState<ProfessionOption[]>();
    const [dates, setDates] = useState<MeetingDateDto[]>();
    const [reload, setReload] = useState<boolean>(true);
    const [upload, setUpload] = useState<LetterTemplateDto>();
    const [showUpload, setShowUpload] = useState<boolean>(false);
    const toggleUpload = () => setShowUpload(!showUpload);

    const [showRemove, setShowRemove] = useState<boolean>(false);
    const toggleRemove = () => setShowRemove(!showRemove);
    const [toRemove, setToRemove] = useState<LetterTemplateDto>();
    const [text, setText] = useState<LetterTemplateDto>();
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef?.current != null) {
            // @ts-ignore
            console.log(editorRef.current.getContent());
        }
    };

    const recommendationStatuses = [
        ProgramStatusActiveOnly.Initial,
        ProgramStatusActiveOnly.Continuing,
        ProgramStatusActiveOnly.Probation,
        ProgramStatusActiveOnly.Withdraw,
        ProgramStatusActiveOnly.Withhold,
    ];

    useEffect(() => {
        if (reload) {
            setReload(false);
            systemAdministration
                .getLetterTemplates()
                .then((results) => {
                    setTemplates(results.sort((a, b) => a.statusId - b.statusId));
                })
                .catch(() => {
                    toast.error('Error loading letter templates');
                });
        }
    }, [setTemplates, reload]);

    useEffect(() => {
        professionService
            .getProfessions()
            .then((r) => {
                setProfessions(r.filter((r) => r.isDeleted === false));
            })
            .catch(() => {
                toast.error('Error loading professions');
            });
    }, [setProfessions]);

    useEffect(() => {
        professionService
            .getProfessions()
            .then((results) => {
                const mappedProfessions = (
                    results.filter((r) => r.isDeleted === false).sort((a, b) => a.title.localeCompare(b.title)) || []
                ).map((a) => {
                    return {
                        label: a.title,
                        value: a.professionId,
                    };
                });
                setProfessionOptions(mappedProfessions);
                setSelectedProfessions(mappedProfessions);
            })
            .catch(() => {
                toast.error('Error loading professions');
            });
    }, [setProfessionOptions]);

    useEffect(() => {
        systemAdministration
            .getMeetingDates()
            .then((meetings) => {
                setDates(
                    meetings
                        .filter((m) => m.active === true)
                        .sort((a, b) => moment.utc(b.meetingDate).unix() - moment.utc(a.meetingDate).unix()),
                );
            })
            .catch(() => {
                toast.error('Error loading meeting dates');
            });
    }, [setDates]);

    const newUpload = () => {
        setUpload({
            letterTemplateId: 0,
            isWithCitations: false,
            statusId: 0,
        });
        toggleUpload();
    };

    const replaceUpload = (template: LetterTemplateDto) => {
        setUpload({
            ...template,
        });
        toggleUpload();
    };

    const addStagedUpload = (dropData) => {
        if (upload && dropData && dropData[0]) {
            dropData.fileId = uuidv4();

            setUpload({
                ...upload,
                fileUpload: dropData[0],
                newFileName: dropData[0].path as string,
            });
        }
    };

    const uploadTemplate = () => {
        if (upload) {
            upload.professionIds =
                selectedProfessions?.length === professions?.length
                    ? undefined
                    : selectedProfessions?.map((d) => +d['value']).join(',');
            const toastId = toast.info('Uploading letter template...');

            systemAdministration
                .uploadLetterTemplate(upload)
                .then(() => {
                    toast.update(toastId, {
                        render: 'Template uploaded successfully',
                        type: 'success',
                    });

                    setReload(true);
                    toggleUpload();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error uploading template',
                        type: 'error',
                    });
                });
        }
    };

    const removeTemplate = () => {
        if (toRemove) {
            const toastId = toast.info('Removing letter template...');

            systemAdministration
                .removeLetterTemplate(toRemove)
                .then(() => {
                    toast.update(toastId, {
                        render: 'Template removed successfully',
                        type: 'success',
                    });

                    setReload(true);
                    toggleRemove();
                })
                .catch(() => {
                    toast.update(toastId, {
                        render: 'Error removing template',
                        type: 'error',
                    });
                });
        }
    };

    const isDuplicateTemplate = (): boolean => {
        if (upload && templates) {
            return (
                templates.find(
                    (t) =>
                        t.isWithCitations === upload.isWithCitations &&
                        t.statusId === upload.statusId &&
                        t.professionIds === upload.professionIds &&
                        t.meetingDateId === upload.meetingDateId &&
                        t.letterTemplateId !== upload.letterTemplateId,
                ) !== undefined
            );
        }

        return false;
    };

    const isMissingStatus = (): boolean => {
        if (upload) {
            return (upload.statusId || 0) === 0;
        }

        return false;
    };

    const isMissingUpload = (): boolean => {
        if (upload) {
            return upload.letterTemplateId === 0 && !upload.fileUpload;
        }

        return false;
    };

    const isValid = (): boolean => {
        let valid = true;

        if (!upload) {
            return false;
        }

        if (isMissingUpload()) {
            valid = false;
        }

        if (isDuplicateTemplate()) {
            valid = false;
        }

        if (isMissingStatus()) {
            valid = false;
        }

        return valid;
    };

    // @ts-ignore
    return (
        <>
            <PageTitle>
                <div className={'d-flex justify-content-between py-3'}>
                    <h4>{`Recommendation Letter Templates`}</h4>
                    <Button type={'button'} color={'primary'} onClick={() => newUpload()}>
                        <i className={'mdi mdi-plus'} />
                        <span className={'ml-2'}>{`New Letter Template`}</span>
                    </Button>
                </div>
            </PageTitle>

            <Card>
                <Table className={'mb-0'} responsive>
                    <thead>
                        <tr>
                            <th>{`Status`}</th>
                            <th>{`Citations?`}</th>
                            <th>{`Profession`}</th>
                            <th>{`Meeting Date`}</th>
                            <th>{`Template Name`}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(templates?.length || 0) > 0 &&
                            templates?.map((t) => (
                                <tr key={t.letterTemplateId}>
                                    <td>{ProgramStatusActiveOnly[t.statusId]}</td>
                                    <td>{t.isWithCitations ? 'With Citations' : 'Without Citations'}</td>
                                    {t.professionIds != undefined &&
                                    t.professionIds?.split(',').length > 0 &&
                                    professions ? (
                                        <td>
                                            {professions
                                                .filter((p) =>
                                                    t.professionIds?.split(',').map(Number)?.includes(p.professionId),
                                                )
                                                .map((p) => p.title)
                                                .join(',')}
                                        </td>
                                    ) : (
                                        <td>{`All Professions`}</td>
                                    )}
                                    {t.meetingDateId && dates && (
                                        <td>
                                            {moment(
                                                dates.find((d) => d.meetingDateId === t.meetingDateId)?.meetingDate,
                                            ).format('MM/DD/YYYY')}
                                        </td>
                                    )}
                                    {!t.meetingDateId && <td>{`All Meetings`}</td>}
                                    <td>{t.originalFilename}</td>
                                    <td className={'text-right'}>
                                        <ButtonGroup>
                                            <a
                                                className={'btn btn-icon btn-light'}
                                                href={`${APIUriAms}/system/letter-template/${t.letterTemplateId}/download`}
                                            >
                                                <i className={`mdi mdi-download`} />
                                                <span className={'ml-1'}>{`Download`}</span>
                                            </a>
                                            <Button type={'button'} color={'primary'} onClick={() => replaceUpload(t)}>
                                                <i className={`mdi mdi-upload`} />
                                                <span className={'ml-1'}>{`Upload`}</span>
                                            </Button>
                                            <Button
                                                type={'button'}
                                                color={'danger'}
                                                onClick={() => {
                                                    setToRemove(t);
                                                    toggleRemove();
                                                }}
                                            >
                                                <i className={`mdi mdi-close`} />
                                                <span className={'ml-1'}>{`Remove`}</span>
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        {(templates?.length || 0) === 0 && (
                            <tr>
                                <td colSpan={2}>{`No templates uploaded`}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card>

            {upload && (
                <Modal isOpen={showUpload} toggle={toggleUpload} centered size={'lg'}>
                    <Card className={'mb-0'}>
                        <CardBody>
                            <div className={'d-flex flex-column'}>
                                {upload.letterTemplateId === 0 && (
                                    <h4 className={'card-title'}>{`New Letter Template`}</h4>
                                )}
                                {upload.letterTemplateId > 0 && (
                                    <h4 className={'card-title'}>{`Update Letter Template`}</h4>
                                )}
                                <FormGroup>
                                    <Label>{`Status`}</Label>
                                    <Input
                                        type={'select'}
                                        value={upload.statusId}
                                        onChange={(e) => setUpload({ ...upload, statusId: +e.target.value })}
                                    >
                                        <option value={0}>{`Select`}</option>
                                        {recommendationStatuses.map((s) => (
                                            <option key={s} value={s}>
                                                {ProgramStatusActiveOnly[s]}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>

                                <FormGroup className={'d-flex'}>
                                    <div className={'custom-control custom-radio'}>
                                        <input
                                            type={'radio'}
                                            id={`noCit`}
                                            className={'custom-control-input'}
                                            checked={upload.isWithCitations === false}
                                            onClick={(e) => setUpload({ ...upload, isWithCitations: false })}
                                            value={0}
                                        />
                                        <Label
                                            className={'custom-control-label'}
                                            for={`noCit`}
                                        >{`Without Citations`}</Label>
                                    </div>
                                    <div className={'custom-control custom-radio ml-3'}>
                                        <input
                                            type={'radio'}
                                            id={`cit`}
                                            className={'custom-control-input'}
                                            checked={upload.isWithCitations === true}
                                            onClick={(e) => setUpload({ ...upload, isWithCitations: true })}
                                            value={1}
                                        />
                                        <Label className={'custom-control-label'} for={`cit`}>{`With Citations`}</Label>
                                    </div>
                                </FormGroup>
                                {professionOptions && professionOptions?.length > 0 && (
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-2">
                                        <Label for="profession" className="mr-sm-2">
                                            Professions
                                        </Label>
                                        <StyledMultiSelect
                                            options={professionOptions}
                                            disableSearch={true}
                                            hasSelectAll={true}
                                            value={selectedProfessions}
                                            onChange={setSelectedProfessions}
                                            required
                                        />
                                    </FormGroup>
                                )}
                                {dates && (
                                    <FormGroup>
                                        <Label>{`Meeting Date`}</Label>
                                        <Input
                                            type={'select'}
                                            value={upload.meetingDateId}
                                            onChange={(e) => setUpload({ ...upload, meetingDateId: +e.target.value })}
                                        >
                                            <option value={undefined}>{`All Meetings`}</option>
                                            {dates.map((d) => (
                                                <option key={d.meetingDateId} value={d.meetingDateId}>
                                                    {moment(d.meetingDate).format('MM/DD/YYYY')}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                )}

                                {upload.newFileName && (
                                    <div className={'mb-1'}>
                                        <span>{`New File Name:`}</span>
                                        <Label className={'ml-3'}>{upload.newFileName}</Label>
                                    </div>
                                )}

                                <Editor
                                    apiKey="your-api-key"
                                    // @ts-ignore
                                    onInit={(_evt, editor) => (editorRef.current = editor)}
                                    initialValue="<p>This is the initial content of the editor.</p>"
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist',
                                            'autolink',
                                            'lists',
                                            'link',
                                            'image',
                                            'charmap',
                                            'preview',
                                            'anchor',
                                            'searchreplace',
                                            'visualblocks',
                                            'code',
                                            'fullscreen',
                                            'insertdatetime',
                                            'media',
                                            'table',
                                            'code',
                                            'help',
                                            'wordcount',
                                        ],
                                        toolbar:
                                            'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        // eslint-disable-next-line @typescript-eslint/camelcase
                                        content_style:
                                            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    }}
                                />
                                <button onClick={log}>Log editor content</button>
                            </div>
                        </CardBody>
                        <CardFooter>
                            {!isValid() && (
                                <Alert color={'danger'} className={'mb-3'}>
                                    {isMissingStatus() && (
                                        <div>
                                            <Label>{`Please select a status.`}</Label>
                                        </div>
                                    )}
                                    {!isMissingStatus() && !isDuplicateTemplate() && isMissingUpload() && (
                                        <div>
                                            <Label>{`Please select a letter template to upload.`}</Label>
                                        </div>
                                    )}
                                    {!isMissingStatus() && isDuplicateTemplate() && (
                                        <div>
                                            <Label>{`A letter template for the selected status, citations, profession, and meeting date already exists.`}</Label>
                                        </div>
                                    )}
                                </Alert>
                            )}
                            <Button
                                type={'button'}
                                color={'primary'}
                                disabled={!isValid()}
                                onClick={() => uploadTemplate()}
                            >{`Save`}</Button>
                            <Button
                                type={'button'}
                                color={'secondary'}
                                className={'ml-2'}
                                onClick={() => toggleUpload()}
                            >{`Cancel`}</Button>
                        </CardFooter>
                    </Card>
                </Modal>
            )}

            {toRemove && (
                <Modal isOpen={showRemove} toggle={toggleRemove} centered>
                    <Card className={'mb-0'}>
                        <CardBody>
                            <div className={'d-flex flex-column'}>
                                <h4 className={`card-title`}>{`Remove Letter Template`}</h4>

                                <p>
                                    {`Please confirm below to remove the letter template. Once completed, this cannot be undone, but you may re-upload the template, if necessary.`}
                                </p>

                                <FormGroup>
                                    <Label>{`Status`}</Label>
                                    <span className={'d-block mt-'}>{ProgramStatusActiveOnly[toRemove.statusId]}</span>
                                </FormGroup>

                                <FormGroup>
                                    <Label>{`Citations?`}</Label>
                                    <span className={'d-block mt-'}>
                                        {toRemove.isWithCitations === true ? 'With Citations' : 'Without Citations'}
                                    </span>
                                </FormGroup>

                                <FormGroup>
                                    <Label>{`Profession?`}</Label>
                                    <span className={'d-block mt-'}>
                                        {toRemove.professionIds != undefined &&
                                            toRemove.professionIds.split(',').length > 0 &&
                                            professions && (
                                                <td>
                                                    {professions
                                                        .filter((p) =>
                                                            toRemove.professionIds
                                                                ?.split(',')
                                                                .map(Number)
                                                                ?.includes(p.professionId),
                                                        )
                                                        .map((p) => p.title)
                                                        .join(',')}
                                                </td>
                                            )}
                                        {!toRemove.professionIds && <td>{`Profession Not Set`}</td>}
                                    </span>
                                </FormGroup>

                                <FormGroup>
                                    <Label>{`Template Name`}</Label>
                                    <span className={'d-block mt-'}>{toRemove.originalFilename || 'Not Set'}</span>
                                </FormGroup>
                            </div>
                        </CardBody>
                        <CardFooter>
                            <Button
                                type={'button'}
                                color={'primary'}
                                onClick={() => removeTemplate()}
                            >{`Remove Template`}</Button>
                            <Button
                                type={'button'}
                                color={'secondary'}
                                className={'ml-2'}
                                onClick={() => toggleRemove()}
                            >{`Cancel`}</Button>
                        </CardFooter>
                    </Card>
                </Modal>
            )}
        </>
    );
};

export default LetterTemplatesUpdate;
